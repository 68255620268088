import React from 'react';
import './about.scss';

import Layout from '../../Components/Layout/Layout';
import Banner from '../../Components/Banner/Banner';

import { PortableText } from '@portabletext/react';
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @namespace About
 * @function About
 * @author Trevor Cash
 * @since 10/02/21
 * @version 1.0.0
 * @component
 */
export default function About() {
  const data = useStaticQuery(graphql`
    query aboutQuery {
      allSanityAbout {
        nodes {
          banner {
            headline
            image {
              image {
                hotspot {
                  y
                  x
                }
                asset {
                  url
                }
              }
              alt
            }
            _rawSubheadline
          }
          _rawProffesional
        }
      }
    }
  `);
  const banner = data?.allSanityAbout?.nodes[0].banner;
  const professional = data?.allSanityAbout?.nodes[0]._rawProffesional;
  return (
    <Layout className='About'>
      <Banner
        heading={banner?.headline}
        subheading={<PortableText value={banner?._rawSubheadline} />}
        image={banner?.image.image.asset.url}
        alt={banner?.image.alt}
        hotspot={{
          x: banner?.image?.image?.hotspot?.x,
          y: banner?.image?.image?.hotspot?.y,
        }}
      />
      <section className='Inner-Frame'>
        <PortableText value={professional} />
      </section>
    </Layout>
  );
}
